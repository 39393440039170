// src/pages/Lines.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Tooltip,
  Chip,
  Box,
  IconButton,
  CircularProgress,
  Alert
} from '@mui/material';
import {
  Add as AddIcon,
  QrCode as QrCodeIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import axios from 'axios';

function Lines() {
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openQRDialog, setOpenQRDialog] = useState(false);
  const [selectedQR, setSelectedQR] = useState(null);
  const [newLineName, setNewLineName] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedLine, setSelectedLine] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const [editingLine, setEditingLine] = useState(null);
  const [editedName, setEditedName] = useState('');

  useEffect(() => {
    fetchLines();
  }, []);

  const fetchLines = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('http://localhost:3000/lines', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setLines(response.data);
      setError(null);
    } catch (error) {
      console.error('Hatlar yüklenirken hata:', error);
      setError('Hatlar yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (line) => {
  setEditingLine(line);
  setEditedName(line.name);
  setEditDialog(true);
};

const handleEditConfirm = async () => {
  try {
    setError(null);
    const token = localStorage.getItem('token');
    await axios.put(`http://localhost:3000/lines/${editingLine.id}`, 
      { name: editedName },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    
    setEditDialog(false);
    setEditingLine(null);
    fetchLines(); // Listeyi yenile
  } catch (error) {
    console.error('Hat güncellenirken hata:', error);
    setError('Hat güncellenirken bir hata oluştu');
  }
};

  const handleDeleteClick = (line) => {
    setSelectedLine(line);
    setDeleteDialog(true);
  };


const handleDeleteConfirm = async () => {
  try {
    setError(null); // Önceki hataları temizle
    const token = localStorage.getItem('token');
    await axios.delete(`http://localhost:3000/lines/${selectedLine.id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    setDeleteDialog(false);
    setSelectedLine(null);
    fetchLines(); // Listeyi yenile
  } catch (error) {
    console.error('Hat silinirken hata:', error);
    setError('Hat silinirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
    setDeleteDialog(false);
  }
};

  const handleAddLine = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('http://localhost:3000/lines/add', 
        { name: newLineName },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setOpenAddDialog(false);
      setNewLineName('');
      fetchLines(); // Listeyi yenile
    } catch (error) {
      console.error('Hat eklenirken hata:', error);
      setError('Hat eklenirken bir hata oluştu');
    }
  };

  const handleShowQR = (qrCode) => {
    setSelectedQR(qrCode);
    setOpenQRDialog(true);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
 <Box sx={{ p: 3 }}>
   <Paper sx={{ p: 3 }}>
     <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
       <Typography variant="h6">
         Hatlar
       </Typography>
       <Button
         variant="contained"
         startIcon={<AddIcon />}
         onClick={() => setOpenAddDialog(true)}
       >
         Yeni Hat Ekle
       </Button>
     </Box>

     {error && (
       <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
     )}

     <Grid container spacing={3}>
       {lines.map((line) => (
         <Grid item xs={12} sm={6} md={4} key={line.id}>
           <Card>
             <CardContent>
               <Typography variant="h6" component="div" gutterBottom>
                 {line.name}
               </Typography>
               <Chip
                 label={line.status === 'active' ? 'Aktif' : 'Pasif'}
                 color={line.status === 'active' ? 'success' : 'warning'}
                 size="small"
                 sx={{ mb: 1 }}
               />
               <Typography variant="body2" color="text.secondary">
                 Oluşturulma: {new Date(line.created_at).toLocaleDateString('tr-TR')}
               </Typography>
             </CardContent>
             <CardActions>
               <Tooltip title="QR Kodu Göster">
                 <IconButton 
                   size="small" 
                   color="primary"
                   onClick={() => handleShowQR(line.qr_code)}
                 >
                   <QrCodeIcon />
                 </IconButton>
               </Tooltip>
               <Tooltip title="Düzenle">
                 <IconButton 
                   size="small" 
                   color="primary"
                   onClick={() => handleEditClick(line)}
                 >
                   <EditIcon />
                 </IconButton>
               </Tooltip>
               <Tooltip title="Sil">
                 <IconButton 
                   size="small" 
                   color="error"
                   onClick={() => handleDeleteClick(line)}
                 >
                   <DeleteIcon />
                 </IconButton>
               </Tooltip>
             </CardActions>
           </Card>
         </Grid>
       ))}
     </Grid>

     {/* Yeni Hat Ekleme Dialog'u */}
     <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
       <DialogTitle>Yeni Hat Ekle</DialogTitle>
       <DialogContent>
         <TextField
           autoFocus
           margin="dense"
           label="Hat Adı"
           fullWidth
           value={newLineName}
           onChange={(e) => setNewLineName(e.target.value)}
         />
       </DialogContent>
       <DialogActions>
         <Button onClick={() => setOpenAddDialog(false)}>İptal</Button>
         <Button onClick={handleAddLine} variant="contained">
           Ekle
         </Button>
       </DialogActions>
     </Dialog>

     {/* QR Kod Dialog'u */}
     <Dialog 
       open={openQRDialog} 
       onClose={() => setOpenQRDialog(false)}
       maxWidth="sm"
       fullWidth
     >
       <DialogTitle>QR Kod</DialogTitle>
       <DialogContent>
         {selectedQR && (
           <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
             <img 
               src={selectedQR} 
               alt="QR Code" 
               style={{ maxWidth: '100%', height: 'auto' }}
             />
           </Box>
         )}
       </DialogContent>
       <DialogActions>
         <Button onClick={() => setOpenQRDialog(false)}>Kapat</Button>
       </DialogActions>
     </Dialog>

     {/* Hat Silme Dialog'u */}
     <Dialog
       open={deleteDialog}
       onClose={() => setDeleteDialog(false)}
     >
       <DialogTitle>Hat Silme Onayı</DialogTitle>
       <DialogContent>
         <DialogContentText>
           "{selectedLine?.name}" hattını silmek istediğinizden emin misiniz? 
           Bu işlem geri alınamaz.
         </DialogContentText>
       </DialogContent>
       <DialogActions>
         <Button onClick={() => setDeleteDialog(false)}>İptal</Button>
         <Button 
           onClick={handleDeleteConfirm} 
           color="error" 
           variant="contained"
         >
           Sil
         </Button>
       </DialogActions>
     </Dialog>

     {/* Hat Düzenleme Dialog'u */}
     <Dialog
       open={editDialog}
       onClose={() => setEditDialog(false)}
     >
       <DialogTitle>Hat Düzenle</DialogTitle>
       <DialogContent>
         <TextField
           autoFocus
           margin="dense"
           label="Hat Adı"
           fullWidth
           value={editedName}
           onChange={(e) => setEditedName(e.target.value)}
         />
       </DialogContent>
       <DialogActions>
         <Button onClick={() => setEditDialog(false)}>İptal</Button>
         <Button 
           onClick={handleEditConfirm} 
           color="primary" 
           variant="contained"
           disabled={!editedName.trim()}
         >
           Kaydet
         </Button>
       </DialogActions>
     </Dialog>
   </Paper>
 </Box>
);
}

export default Lines;