// src/pages/Reports.js
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Box,
  CircularProgress,
  Container,
  Alert,
  Button,
  Checkbox,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

function Reports() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false); // Dialog için durum
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchMessages();
    const interval = setInterval(fetchMessages, 30000);
    return () => clearInterval(interval);
  }, []);

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('http://localhost:3000/sms/status', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setMessages(response.data);
      setError(null);
    } catch (error) {
      console.error('Mesajlar yüklenirken hata:', error);
      setError('Mesajlar yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelectedIds = messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((message) => message.id);
      setSelectedIds(newSelectedIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete('http://localhost:3000/sms/delete-multiple', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: { ids: selectedIds }
      });
      setSelectedIds([]);
      fetchMessages();
      setOpenDialog(false); // Dialog kapat
    } catch (error) {
      console.error('Toplu silme hatası:', error);
      setError('Seçilen SMS mesajları silinirken bir hata oluştu');
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'sent':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return 'default';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'pending':
        return 'Bekliyor';
      case 'sent':
        return 'Gönderildi';
      case 'failed':
        return 'Başarısız';
      default:
        return status;
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ mt: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Paper sx={{ p: 3, mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          SMS Raporları
        </Typography>
        
        {selectedIds.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleOpenDialog}
            >
              Seçilenleri Sil
            </Button>
          </Box>
        )}

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Seçilenleri Sil"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Seçilen SMS mesajlarını silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              İptal
            </Button>
            <Button onClick={handleDeleteSelected} color="error" autoFocus>
              Sil
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    indeterminate={selectedIds.length > 0 && selectedIds.length < messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
                    checked={selectedIds.length > 0 && selectedIds.length === messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Tarih</TableCell>
                <TableCell>Hat</TableCell>
                <TableCell>Alıcı</TableCell>
                <TableCell>Mesaj</TableCell>
                <TableCell>Durum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((message) => (
                <TableRow key={message.id}>
                  <TableCell>
                    <Checkbox
                      checked={selectedIds.includes(message.id)}
                      onChange={() => handleSelect(message.id)}
                    />
                  </TableCell>
                  <TableCell>{formatDate(message.created_at)}</TableCell>
                  <TableCell>{message.line_name}</TableCell>
                  <TableCell>{message.recipient}</TableCell>
                  <TableCell sx={{ maxWidth: 300, whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {message.message}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={getStatusText(message.status)}
                      color={getStatusColor(message.status)}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={messages.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Sayfa başına SMS"
        />
      </Paper>
    </Container>
  );
}

export default Reports;