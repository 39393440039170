import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tab,
  Tabs,
  Chip,
  Alert,
  Link,
  Snackbar
} from '@mui/material';
import { Upload as UploadIcon } from '@mui/icons-material';
import axios from 'axios';

function SendSMS() {
  // State tanımlamaları
  const [activeTab, setActiveTab] = useState(0);  // 0: Tek Numara, 1: Excel ile Toplu
  const [lines, setLines] = useState([]);         // Hat listesi
  const [loading, setLoading] = useState(false);  // Yükleniyor durumu
  const [phoneNumbers, setPhoneNumbers] = useState([]); // Çoklu numara state'i
  const [alert, setAlert] = useState({            // Alert mesajları için
    open: false,
    message: '',
    severity: 'success'
  });
  const [formData, setFormData] = useState({      // Form verileri
    phoneNumber: '',
    message: '',
    selectedLine: '',
    file: null
  });
  const [excelData, setExcelData] = useState({
    numbers: [],
    isValid: false,
    errorMessage: ''
  });

  // Sayfa yüklendiğinde hatları getir
  useEffect(() => {
    fetchLines();
  }, []);

  // Hat listesini getiren fonksiyon
  const fetchLines = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('http://localhost:3000/lines', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setLines(response.data);
    } catch (error) {
      console.error('Hatlar yüklenirken hata:', error);
      setAlert({
        open: true,
        message: 'Hatlar yüklenirken hata oluştu',
        severity: 'error'
      });
    }
  };

  // Tab değişikliğini yöneten fonksiyon
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Form input değişikliklerini yöneten fonksiyon
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Telefon numarası değişikliklerini yöneten fonksiyon
  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    
    // Virgül veya enter ile ayrılmış numaraları al
    const numbers = value.split(/[,\n]/).map(num => num.trim()).filter(num => num);
    
    setFormData(prev => ({
      ...prev,
      phoneNumber: value
    }));

    // Geçerli numaraları state'e kaydet
    setPhoneNumbers(numbers);
  };

  // Excel dosyası yükleme fonksiyonu
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post('http://localhost:3000/sms/upload-numbers', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.numbers) {
        setExcelData({
          numbers: response.data.numbers,
          isValid: true,
          errorMessage: ''
        });
        setPhoneNumbers(response.data.numbers); // Excel numaralarını phoneNumbers state'ine ekleyin
      }
    } catch (error) {
      console.error('Excel dosyası işlenirken hata oluştu:', error);
      setExcelData({
        numbers: [],
        isValid: false,
        errorMessage: error.response?.data?.message || 'Excel dosyası işlenirken hata oluştu'
      });
      setAlert({
        open: true,
        message: 'Excel dosyası işlenirken hata oluştu',
        severity: 'error'
      });
    }
  };

  // SMS gönderme işlemini yapan fonksiyon
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      // Form validasyonu
      if (!formData.selectedLine) {
        setAlert({
          open: true,
          message: 'Lütfen bir hat seçin',
          severity: 'error'
        });
        return;
      }

      if (!formData.message) {
        setAlert({
          open: true,
          message: 'Lütfen bir mesaj girin',
          severity: 'error'
        });
        return;
      }

      if (activeTab === 0) {
        // Tek/çoklu numara gönderimi
        if (phoneNumbers.length === 0) {
          setAlert({
            open: true,
            message: 'Lütfen en az bir telefon numarası girin',
            severity: 'error'
          });
          return;
        }

        const response = await axios.post('http://localhost:3000/sms/send', {
          phone_line_id: parseInt(formData.selectedLine),
          numbers: phoneNumbers,
          message: formData.message
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.data) {
          setAlert({
            open: true,
            message: `${phoneNumbers.length} numaraya SMS başarıyla gönderildi`,
            severity: 'success'
          });
          // Form'u temizle
          setFormData({
            phoneNumber: '',
            message: '',
            selectedLine: formData.selectedLine,
            file: null
          });
          setPhoneNumbers([]);
        }
      } else {
        // Excel ile toplu gönderim
        if (!formData.file) {
          setAlert({
            open: true,
            message: 'Lütfen bir Excel dosyası seçin',
            severity: 'error'
          });
          return;
        }

        if (!excelData.isValid || excelData.numbers.length === 0) {
          setAlert({
            open: true,
            message: 'Geçerli telefon numaraları içeren bir Excel dosyası yükleyin',
            severity: 'error'
          });
          return;
        }

        const response = await axios.post('http://localhost:3000/sms/send', {
          phone_line_id: parseInt(formData.selectedLine),
          numbers: excelData.numbers,
          message: formData.message
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.data) {
          setAlert({
            open: true,
            message: `${excelData.numbers.length} numaraya SMS gönderimi başlatıldı`,
            severity: 'success'
          });
          // Form'u temizle
          setFormData({
            phoneNumber: '',
            message: '',
            selectedLine: formData.selectedLine,
            file: null
          });
          setExcelData({
            numbers: [],
            isValid: false,
            errorMessage: ''
          });
        }
      }
    } catch (error) {
      console.error('SMS gönderirken hata:', error);
      setAlert({
        open: true,
        message: error.response?.data?.message || 'SMS gönderilirken bir hata oluştu',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Excel numaralarını gösterecek komponent
  const ExcelNumbersList = () => {
    if (!excelData.numbers.length) return null;

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Excel'den Yüklenen Numaralar ({excelData.numbers.length})
        </Typography>
        <Paper 
          sx={{ 
            maxHeight: 200, 
            overflow: 'auto',
            p: 2
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {excelData.numbers.map((number, index) => (
              <Chip
                key={index}
                label={number}
                size="small"
                color={number.length === 11 ? "primary" : "error"}
              />
            ))}
          </Box>
        </Paper>
        {excelData.errorMessage && (
          <Typography color="error" variant="caption">
            {excelData.errorMessage}
          </Typography>
        )}
      </Box>
    );
  };

  // Alert kapatma fonksiyonu
  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <Box sx={{ width: '100%', p: { xs: 2, sm: 3 } }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          SMS Gönder
        </Typography>

        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}
        >
          <Tab label="Tek Numara" />
          <Tab label="Excel ile Toplu Gönderim" />
        </Tabs>

        {activeTab === 1 && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Excel dosyasında telefon numaralarının olduğu bir sütun bulunmalıdır. 
            Örnek şablonu <Link href="#" onClick={(e) => e.preventDefault()}>buradan</Link> indirebilirsiniz.
          </Alert>
        )}

        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Hat Seçin</InputLabel>
            <Select
              name="selectedLine"
              value={formData.selectedLine}
              onChange={handleInputChange}
              label="Hat Seçin"
            >
              {lines.map((line) => (
                <MenuItem key={line.id} value={line.id}>
                  {line.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {activeTab === 0 ? (
            <>
              <TextField
                fullWidth
                label="Telefon Numarası"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handlePhoneNumberChange}
                multiline
                rows={3}
                sx={{ mb: 2 }}
                placeholder="Her satıra bir numara yazın veya virgülle ayırın"
                helperText="Örnek: 05551234567, 05551234568"
              />
              {phoneNumbers.length > 0 && (
                <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {phoneNumbers.map((number, index) => (
                    <Chip
                      key={index}
                      label={number}
                      color={number.length === 11 ? "primary" : "error"}
                      onDelete={() => {
                        const newNumbers = phoneNumbers.filter((_, i) => i !== index);
                        setPhoneNumbers(newNumbers);
                        setFormData(prev => ({
                          ...prev,
                          phoneNumber: newNumbers.join(', ')
                        }));
                      }}
                    />
                  ))}
                </Box>
              )}
            </>
          ) : (
            <>
              <Box 
                sx={{ 
                  border: '2px dashed #ccc',
                  borderRadius: 2,
                  p: 3,
                  mb: 2,
                  textAlign: 'center',
                  backgroundColor: '#fafafa',
                  cursor: 'pointer',
                  width: '100%',
                  minHeight: '200px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    borderColor: 'primary.main',
                    backgroundColor: '#f5f5f5'
                  }
                }}
                component="label"
              >
                <input
                  type="file"
                  hidden
                  onChange={handleFileUpload}
                  accept=".xlsx,.xls"
                />
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%'
                }}>
                  <UploadIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Excel Dosyası Yükle
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {formData.file ? formData.file.name : 'Dosya seçmek için tıklayın veya sürükleyin'}
                  </Typography>
                  {formData.file && (
                    <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Chip 
                        label={formData.file.name}
                        onDelete={() => setFormData(prev => ({ ...prev, file: null }))}
                        color="primary"
                        variant="outlined"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <ExcelNumbersList />
            </>
          )}

          <TextField
            fullWidth
            label="Mesaj"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? 'Gönderiliyor...' : 'Gönder'}
          </Button>
        </Box>
      </Paper>

      <Snackbar 
        open={alert.open} 
        autoHideDuration={6000} 
        onClose={handleCloseAlert}
      >
        <Alert 
          onClose={handleCloseAlert} 
          severity={alert.severity} 
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SendSMS;